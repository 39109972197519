export const GET_HOST_REQUEST = "GET_HOST_REQUEST";

export const ACCEPT_HOST_REQUEST = "ACCEPT_HOST_REQUEST";


export const OPEN_REASON_DIALOGUE = "OPEN_REASON_DIALOGUE";
export const CLOSE_REASON_DIALOGUE = "CLOSE_REASON_DIALOGUE";


export const OPEN_INFO_DIALOGUE = "OPEN_INFO_DIALOGUE";
export const CLOSE_INFO_DIALOGUE = "CLOSE_INFO_DIALOGUE";

