import React, { useState, useEffect } from "react";
import {
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
  Tooltip,
} from "@material-ui/core";
import { Cancel } from "@material-ui/icons";
import { useDispatch, useSelector } from "react-redux";
import { CLOSE_INFO_DIALOGUE } from "../../store/hostRequest/types";

const InfoDialogue = () => {
  const dispatch = useDispatch();
  const { dialog1: open, dialogData1 } = useSelector(
    (state) => state.hostRequest
  );

  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [age, setAge] = useState("");
  const [country, setCountry] = useState("");
  const [imagePath, setImagePath] = useState("");
  const [videoPath, setVideoPath] = useState("");

  useEffect(() => {
    if (dialogData1) {
      setName(dialogData1?.name);
      setEmail(dialogData1?.email);
      setCountry(dialogData1?.country);
      setAge(dialogData1?.age);
      setImagePath(dialogData1?.image);
      setVideoPath(dialogData1?.video);
    }
  }, [dialogData1, open]);

  const closePopup = () => {
    dispatch({ type: CLOSE_INFO_DIALOGUE });
  };

  return (
    <>
      <Dialog
        open={open}
        aria-labelledby="responsive-dialog-title"
        onClose={closePopup}
        disableBackdropClick
        disableEscapeKeyDown
        fullWidth
        sx={{ maxWidth: "400px" }}
      >
        <DialogTitle id="responsive-dialog-title">
          <span className="text-white font-weight-bold h4">Info Dialog</span>
        </DialogTitle>

        <IconButton
          style={{
            position: "absolute",
            right: 0,
          }}
        >
          <Tooltip title="Close">
            <Cancel className="text-danger" onClick={closePopup} />
          </Tooltip>
        </IconButton>

        <DialogContent
          className="mb-2"
          sx={{
            maxHeight: "60vh", // Adjust this value as needed
            overflowY: "auto",
          }}
        >
          <div className="form-group col-12 my-3">
            <label className="mb-2 text-white">Name</label>
            <input
              type="text"
              className="form-control-input d-block"
              placeholder="Name"
              readOnly
              value={name}
            />

            <label className="mb-2 text-white mt-2">Email</label>
            <input
              type="text"
              className="form-control-input d-block"
              placeholder="Email"
              readOnly
              value={email}
            />

            <label className="mb-2 text-white mt-2">Age</label>
            <input
              type="text"
              className="form-control-input d-block"
              placeholder="Age"
              readOnly
              value={age}
            />

            <label className="mb-2 text-white mt-2">Country</label>
            <input
              type="text"
              className="form-control-input d-block"
              placeholder="Country"
              readOnly
              value={country}
            />
            <div className="d-flex align-items-center justify-content-between">
              <div>
                <label className="mb-2 text-white mt-2 d-block">Image</label>
                {imagePath ? (
                  <img
                    src={imagePath}
                    alt="User Image"
                    style={{
                      width: "100px",
                      maxHeight: "100px",
                      objectFit: "cover",
                      borderRadius: "8px",
                    }}
                  />
                ) : (
                  <p className="text-white">No image available</p>
                )}
              </div>
              <div>
                <label className="mb-2 text-white mt-2">Video</label>
                <div className="d-flex">
                  {/* Display Video */}
                  {videoPath ? (
                    <video
                      controls
                      style={{
                        width: "100px",
                        maxHeight: "100px",
                        objectFit: "cover",
                        borderRadius: "8px",
                      }}
                    >
                      <source src={videoPath} type="video/mp4" />
                      Your browser does not support the video tag.
                    </video>
                  ) : (
                    <p className="text-white">No video available</p>
                  )}
                </div>
              </div>
            </div>
            {/* Display Image */}

            <div className="pt-3">
              <button
                type="button"
                className="btn btn-outline-info ml-2 btn-round float__right icon_margin"
                onClick={closePopup}
              >
                Close
              </button>
              <button
                type="button"
                className="btn btn-round float__right btn-danger"
              >
                Submit
              </button>
            </div>
          </div>
        </DialogContent>
      </Dialog>
    </>
  );
};

export default InfoDialogue;
