import axios from "axios";
import * as ActionType from "./history.type";

// get admin history

export const getAdminHistory =
  (id, start, limit, startDate, endDate) => (dispatch) => {
    axios
      .get(
        `agencySettlement/agencySettlementForAgency?agencyId=${id}&start=${start}&limit=${limit}&startDate=${startDate}&endDate=${endDate}`
      )
      .then((res) => {
        dispatch({
          type: ActionType.GET_ADMIN_HISTORY,
          payload: { history: res.data.history, total: res.data.total },
        });
      })
      .catch((error) => console.log("error", error));
  };
export const getAdminCashOut = (id, start, limit, date, type) => (dispatch) => {
  axios
    .get(
      `agencyRedeem/getAgencyWise?agencyId=${id}&start=${start}&limit=${limit}&month=${date}&type=${type}`
    )
    .then((res) => {
      dispatch({
        type: ActionType.GET_ADMIN_CASHOUT,
        payload: { history: res.data.data, total: res.data.total },
      });
    })
    .catch((error) => console.log("error", error));
};

export const getHosthistory =
  (id, type, start, limit, startDate, endDate) => (dispatch) => {
    axios
      .get(
        `history/hostHistory?hostId=${id}&type=${type}&start=${start}&limit=${limit}&startDate=${startDate}&endDate=${endDate}`
      )
      .then((res) => {
        dispatch({
          type: ActionType.GET_HOST_HISTORY,
          payload: { history: res.data.history,total: res.data.total },
        });
      })
      .catch((error) => console.log("error", error));
  };

export const getAgencyEarning = (id, date) => (dispatch) => {
  axios
    .get(`weekHistory/agencyEarning?agencyId=${id}&month=${date}`)
    .then((res) => {
      dispatch({
        type: ActionType.GET_ADMIN_HISTORY,
        payload: { history: res.data.data },
      });
    })
    .catch((error) => console.log("error", error));
};
